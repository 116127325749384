import React, { useCallback, useEffect, useLayoutEffect, useMemo } from "react";
import "./InterfaceFeature.scss";
import { useState, useRef } from "react";
import { Container, Button, FormInput } from "../../components";
import { Formik, Form, useFormik } from "formik";
import * as Yup from "yup";
import Multiselect from "multiselect-react-dropdown";
import { RiUpload2Fill } from "react-icons/ri";
import toast from "react-hot-toast";
import {
  CreateInterfaceFeature,
  addFeaturedOccasion,
  clearOccasionsErrors,
  clearOccasionMessages,
  getAllOccasions,
  clearErrors,
  clearMessages,
  getAllOccasions2,
  getFeaturedOccasion,
} from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { eventData } from "../../data/eventData";
import styled from "@emotion/styled";
import {
  DividerStyled,
  HomePageRelatedFormStyled,
  HowCanShowStyled,
  SelectedImagesStyled,
  InterfaceFeatureStyled,
  SelectedOccasionsFormStyled,
  SelectedOccasionsStyled,
} from "./styles";
import { profile } from "../../assests";
import { FaTimes, FaTrash, FaTrashAlt } from "react-icons/fa";
import {
  getUserInface2,
  updateUserInface2,
} from "../../store/actions/userInterface.action2";
import {
  FeatureOccasionsSchema,
  HomePageHeroDescriptionSchema,
  HomePageHeroImageUserInterfaceSchema,
  HomePageUserInterfaceSchema,
  HomePageVideoLinkSchema,
} from "../../schema";
import { v4 } from "uuid";
import fileToBase64 from "../../utils/fileToBase64";
import { uploadFile } from "../../store/actions/file.action";
import isBase64 from "../../utils/isBase64";
import { BACKEND_STATIC_URL } from "../../constants";
import { convertToEmbeddedYouTubeLink } from "../../utils";
import convertToEmbeddedGoogleDriveLink from "../../utils/convertToEmbeddedGoogleDriveLink";

const InterfaceFeature = () => {
  const dispatch = useDispatch();
  const { getUserInfaceData } = useSelector((s) => s.userInterface2Reducer);

  useEffect(() => {
    dispatch(getUserInface2());
    dispatch(getAllOccasions2());
    dispatch(getFeaturedOccasion());
  }, [dispatch]);

  return (
    <InterfaceFeatureStyled>
      <h1>User Interface</h1>
      <HowCanShow />
      <Divider />
      {getUserInfaceData.data?.howOccasionShowOnHomePage === "Selected" && (
        <>
          <SelectedOccasionsForm />
          <Divider />
          <SelectedOccasions />
          <Divider />
        </>
      )}
      <HeroDescriptionForm />
      <Divider />
      <HomePageVideoLinkForm />
      <Divider />
      <HomePageRelatedForm />
      <Divider />
      <SelectedImages />
      <SelectImageForHero />
    </InterfaceFeatureStyled>
  );
};

export default InterfaceFeature;

const HowCanShow = () => {
  const { getUserInfaceData } = useSelector((s) => s.userInterface2Reducer);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(
      updateUserInface2(
        {
          ...getUserInfaceData.data,
          howOccasionShowOnHomePage: isAllSelected ? "All" : "Selected",
        },
        () => {
          dispatch(getUserInface2());
          toast.success("Updated!");
        }
      )
    );
  };

  return (
    <HowCanShowStyled onSubmit={handleSubmit}>
      <div className="row">
        <label htmlFor="">How ocassions could show on home page?</label>
        <div className="group">
          <input
            name="howCanShow"
            type="radio"
            id="All"
            onClick={() => setIsAllSelected(true)}
            checked={isAllSelected}
          />
          <label htmlFor="All">All Occasions</label>
        </div>
        <div className="group">
          <input
            name="howCanShow"
            type="radio"
            id="Selected"
            onClick={() => setIsAllSelected(false)}
            checked={!isAllSelected}
          />
          <label htmlFor="Selected">Selected Occasions</label>
        </div>
      </div>

      <div className="row">
        <button type="submit">Save</button>
      </div>
    </HowCanShowStyled>
  );
};

const SelectedOccasionsForm = () => {
  const { getAllOccasionsData } = useSelector((s) => s.occasion2Reducer);
  const { featuredOccasions } = useSelector((s) => s.occasionReducer);
  const dispatch = useDispatch();

  const { setFieldValue, errors, values, handleSubmit, dirty } = useFormik({
    initialValues: {
      selectedOccasions: [],
    },

    validationSchema: FeatureOccasionsSchema,

    onSubmit: (values) => {
      dispatch(
        addFeaturedOccasion(
          values.selectedOccasions.map((o) => o._id),
          () => {
            dispatch(getFeaturedOccasion());
            toast.success("Updated!");
          }
        )
      );
    },
  });

  useEffect(() => {
    setFieldValue(
      "selectedOccasions",
      featuredOccasions.map((f) => f.occasion)
    );
  }, [featuredOccasions, setFieldValue]);

  return (
    <SelectedOccasionsFormStyled onSubmit={handleSubmit}>
      <div className="row">
        <label htmlFor="selectedOccasions">Select Occasions</label>

        <Multiselect
          onRemove={(sl) => {
            setFieldValue("selectedOccasions", sl);
          }}
          onSelect={(sl) => {
            setFieldValue("selectedOccasions", sl);
          }}
          selectedValues={values.selectedOccasions}
          options={getAllOccasionsData?.data}
          placeholder="Select Occasions"
          displayValue="occasionName"
          selectionLimit={3}
          id="selectedOccasions"
        />
      </div>

      <div className="row">
        <button disabled={Object.keys(errors).length !== 0 || !dirty}>
          Save
        </button>
      </div>
    </SelectedOccasionsFormStyled>
  );
};

const SelectedOccasions = () => {
  const { featuredOccasions } = useSelector((s) => s.occasionReducer);

  return (
    <SelectedOccasionsStyled>
      <h2>Selected Occasions</h2>
      <table>
        <thead>
          <tr>
            <th>Sr No.</th>
            <th>Name</th>
            <th>Image URL</th>
          </tr>
        </thead>
        <tbody>
          {featuredOccasions.map(({ occasion }, i) => (
            <tr key={occasion?._id}>
              <td>{i + 1}</td>
              <td>{occasion.occasionName}</td>
              <td>
                <a
                  href={`${BACKEND_STATIC_URL}${occasion.occasionImage}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {`${BACKEND_STATIC_URL}${occasion.occasionImage}`}
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </SelectedOccasionsStyled>
  );
};

const Divider = () => {
  return <DividerStyled></DividerStyled>;
};

const HeroDescriptionForm = () => {
  const { getUserInfaceData } = useSelector((s) => s.userInterface2Reducer);
  const dispatch = useDispatch();

  const {
    values,

    errors,
    handleBlur,
    handleChange,
    setFieldValue,
    touched,
    handleSubmit,
  } = useFormik({
    initialValues: {
      text: "",
    },
    validationSchema: HomePageHeroDescriptionSchema,

    onSubmit: async (values, { resetForm }) => {
      dispatch(
        updateUserInface2(
          {
            ...getUserInfaceData.data,
            ...values,
          },
          () => {
            toast.success("Updated!");
            dispatch(getUserInface2());
            resetForm();
          }
        )
      );
    },
  });
  useEffect(() => {
    if (getUserInfaceData.data) {
      setFieldValue("text", getUserInfaceData.data?.text);
    }
  }, [getUserInfaceData.data, setFieldValue]);
  return (
    <HomePageRelatedFormStyled onSubmit={handleSubmit}>
      <div className="group">
        <label htmlFor="hero-description">Hero Description</label>
        <input
          id="hero-description"
          value={values.text}
          onChange={handleChange}
          onBlur={handleBlur}
          name="text"
          type="text"
          placeholder="Description"
        />
        {errors.text && touched.text && <p>{errors.text}</p>}
      </div>

      <button type="submit">Save</button>
    </HomePageRelatedFormStyled>
  );
};

const HomePageVideoLinkForm = () => {
  const { getUserInfaceData } = useSelector((s) => s.userInterface2Reducer);
  const dispatch = useDispatch();

  const {
    values,
    errors,
    handleSubmit,
    handleBlur,
    handleChange,
    setFieldValue,
    touched,
  } = useFormik({
    initialValues: {
      videoLink: "",
    },
    validationSchema: HomePageVideoLinkSchema,

    onSubmit: async (values, { resetForm }) => {
      values.videoLink = convertToEmbeddedYouTubeLink(values.videoLink);
      values.videoLink = convertToEmbeddedGoogleDriveLink(values.videoLink);
      dispatch(
        updateUserInface2(
          {
            ...getUserInfaceData.data,
            ...values,
          },
          () => {
            toast.success("Updated!");
            dispatch(getUserInface2());
            resetForm();
          }
        )
      );
    },
  });

  useEffect(() => {
    if (getUserInfaceData.data) {
      setFieldValue("videoLink", getUserInfaceData.data?.videoLink);
    }
  }, [getUserInfaceData.data, setFieldValue]);

  return (
    <HomePageRelatedFormStyled onSubmit={handleSubmit}>
      <div className="group">
        <label htmlFor="video-link">Home Page Video Link</label>
        <input
          id="video-link"
          value={values.videoLink}
          onChange={handleChange}
          onBlur={handleBlur}
          name="videoLink"
          type="text"
          placeholder="Link"
        />
        {errors.videoLink && touched.videoLink && <p>{errors.videoLink}</p>}
      </div>
      <button type="submit">Save</button>
    </HomePageRelatedFormStyled>
  );
};

const HomePageRelatedForm = () => {
  const { getUserInfaceData } = useSelector((s) => s.userInterface2Reducer);
  const dispatch = useDispatch();
  const [allImages, setAllImages] = useState([]);

  const {
    values,
    setFieldValue,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
  } = useFormik({
    initialValues: {
      pictures: [],
    },
    validationSchema: HomePageUserInterfaceSchema,

    onSubmit: async (values, { resetForm }) => {
      if (values.pictures.length === 0) return;

      const postData = (pictures) => {
        dispatch(
          updateUserInface2(
            {
              ...getUserInfaceData.data,
              pictures,
            },
            () => {
              toast.success("Updated!");
              dispatch(getUserInface2());
              resetForm();
              setAllImages([]);
            }
          )
        );
      };

      dispatch(
        uploadFile(
          { files: values.pictures?.map((o) => o.imgURL) },
          (links) => {
            postData([
              ...getUserInfaceData.data?.pictures,
              ...values.pictures.map((t, i) => ({ ...t, imgURL: links[i] })),
            ]);
          }
        )
      );
    },
  });

  const onImageChange = async (e) => {
    const files = Array.from(e.target.files);

    const isLess = files.every((file) => file.size <= 5242880);
    if (!isLess) {
      toast.error("File size must be less than 5MB.");
      e.target.value = "";
      return;
    }

    setFieldValue("pictures", [
      ...values.pictures,
      ...files.map((f) => ({ LinkURL: "", imgURL: f })),
    ]);
    e.target.value = "";
  };

  useEffect(() => {
    const f1 = async () => {
      let files = [];
      for (const obj of values.pictures) {
        const file = await fileToBase64(obj.imgURL);
        files.push({ ...file, imgURL: file });
      }
      setAllImages(files);
    };

    f1();
  }, [values.pictures]);

  const handleInputChange = useCallback(
    (e, index) => {
      const newVal = { ...values.pictures };
      newVal[index].LinkURL = e.target.value;
      setFieldValue("pictures", values.pictures);
    },
    [setFieldValue, values.pictures]
  );

  return (
    <HomePageRelatedFormStyled onSubmit={handleSubmit}>
      <div className="group images">
        <label htmlFor="video-link">Add Carousel Images</label>
        <div>
          {allImages.length > 0 && (
            <div className="images">
              {allImages.map(({ imgURL, LinkURL }, i) => (
                <div className="group" key={i}>
                  <img src={imgURL} alt="" key={i} />
                  <button
                    type="button"
                    onClick={() =>
                      setFieldValue("pictures", [
                        ...values.pictures.slice(0, i),
                        ...values.pictures.slice(i + 1),
                      ])
                    }
                  >
                    <FaTrash />
                  </button>
                  <input
                    type="text"
                    value={LinkURL}
                    onChange={(e) => handleInputChange(e, i)}
                    placeholder="Enter Link"
                  />
                </div>
              ))}
            </div>
          )}

          <label htmlFor="images">
            <span>Select Images</span>
          </label>
          <input
            id="images"
            type="file"
            accept=".png, .jpeg, .jpg"
            multiple
            onChange={onImageChange}
            hidden
          />
        </div>
        {errors.pictures && touched.pictures && <p>{errors.pictures}</p>}
      </div>

      <button type="submit">Save</button>
    </HomePageRelatedFormStyled>
  );
};

const SelectedImages = () => {
  const { getUserInfaceData } = useSelector((s) => s.userInterface2Reducer);
  const dispatch = useDispatch();

  const update = useCallback(
    (link) => {
      dispatch(
        updateUserInface2(
          {
            ...getUserInfaceData.data,
            pictures: getUserInfaceData.data?.pictures.filter(
              (l) => link !== l?.imgURL
            ),
          },
          () => {
            dispatch(getUserInface2());
            toast.success("Updated!");
          }
        )
      );
    },
    [dispatch, getUserInfaceData.data]
  );

  return (
    getUserInfaceData.data?.pictures?.length > 0 && (
      <SelectedImagesStyled>
        <h2>Selected Carousel Pictures</h2>
        <div>
          {getUserInfaceData.data?.pictures?.map((o, index) => (
            <div className="group">
              <img
                src={`${BACKEND_STATIC_URL}${o.imgURL}`}
                key={index}
                alt=""
              />
              <button onClick={() => update(o.imgURL)}>
                <FaTrash />
              </button>
              <SelectedImagesItemForm
                defaultValue={o?.LinkURL}
                imgURL={o?.imgURL}
              />
            </div>
          ))}
        </div>
      </SelectedImagesStyled>
    )
  );
};

const SelectedImagesItemForm = ({ defaultValue, imgURL }) => {
  const [inputValue, setInputValue] = useState(defaultValue);
  const { getUserInfaceData } = useSelector((s) => s.userInterface2Reducer);
  const dispatch = useDispatch();

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      const copy = [...getUserInfaceData.data?.pictures];

      const index = copy.findIndex((l) => imgURL === l?.imgURL);

      if (index === -1) return;

      copy[index] = { ...copy[index], LinkURL: inputValue };

      dispatch(
        updateUserInface2(
          {
            ...getUserInfaceData.data,
            pictures: copy,
          },
          () => {
            dispatch(getUserInface2());
            toast.success("Updated!");
          }
        )
      );
    },
    [dispatch, getUserInfaceData.data, imgURL, inputValue]
  );

  return (
    <form onSubmit={onSubmit}>
      <input
        type="text"
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        placeholder="Enter Link"
      />
      <button type="submit">Save</button>
    </form>
  );
};

const SelectImageForHero = () => {
  const { getUserInfaceData } = useSelector((s) => s.userInterface2Reducer);
  const dispatch = useDispatch();
  const [allImages, setAllImages] = useState([]);

  const { values, setFieldValue, errors, handleSubmit, touched } = useFormik({
    initialValues: {
      picture: [],
    },
    validationSchema: HomePageHeroImageUserInterfaceSchema,

    onSubmit: async (values, { resetForm }) => {
      if (values.picture.length === 0) return;

      const postData = (heroImages) => {
        dispatch(
          updateUserInface2(
            {
              ...getUserInfaceData.data,
              heroImage: heroImages[0].imgURL,
            },
            () => {
              toast.success("Updated!");
              dispatch(getUserInface2());
              resetForm();
              setAllImages([]);
            }
          )
        );
      };

      dispatch(
        uploadFile({ files: values.picture?.map((o) => o.imgURL) }, (links) => {
          postData([...values.picture.map((t, i) => ({ imgURL: links[i] }))]);
        })
      );
    },
  });

  const onImageChange = async (e) => {
    const file = e.target.files[0];
    if (!file) {
      return;
    }

    const isLess = file.size <= 5242880;
    if (!isLess) {
      toast.error("File size must be less than 5MB.");
      e.target.value = "";
      return;
    }
    setFieldValue("picture", [{ imgURL: file }]);
    e.target.value = "";
  };

  const _allImages = useMemo(() => {
    return allImages.length > 0
      ? allImages
      : getUserInfaceData?.data?.heroImage
      ? [
          {
            imgURL: `${BACKEND_STATIC_URL}${getUserInfaceData?.data?.heroImage}`,
          },
        ]
      : [];
  }, [allImages, getUserInfaceData?.data?.heroImage]);

  useEffect(() => {
    const f1 = async () => {
      let files = [];
      for (const obj of values.picture) {
        const file = await fileToBase64(obj.imgURL);
        files.push({ imgURL: file });
      }
      setAllImages(files);
    };

    f1();
  }, [values.picture]);
  return (
    <HomePageRelatedFormStyled onSubmit={handleSubmit}>
      <div className="group images">
        <label htmlFor="video-link">Image For Landing Page Hero</label>
        <div>
          {_allImages.length > 0 && (
            <div className="images only-one">
              {_allImages.map(({ imgURL }, i) => (
                <div className="group" key={i}>
                  <img src={imgURL} className="only-one" alt="" key={i} />
                </div>
              ))}
            </div>
          )}

          <label htmlFor="images-hero">
            <span>{_allImages?.length === 0 ? "Select" : "Change"} Image</span>
          </label>
          <input
            id="images-hero"
            type="file"
            accept=".png, .jpeg, .jpg"
            onChange={onImageChange}
            hidden
          />
        </div>
        {errors.picture && touched.picture && <p>{errors.picture}</p>}
      </div>

      <button type="submit">Save</button>
    </HomePageRelatedFormStyled>
  );
};
