import { useDispatch, useSelector } from "react-redux";
// import "./ServiceTypeDetail.scss"
import { useEffect, useRef, useState } from "react";
import {
  getSingleServiceType,
  updateServiceType,
  clearServiceTypeErrors,
  clearOccasionMessages,
  clearServiceTypeMessages,
} from "../../store/actions";
import { Container, Heading, InfoItem } from "../../components";
import { useNavigate, useParams } from "react-router-dom";
import { RiUpload2Fill } from "react-icons/ri";
import { Button } from "../../components";
import toast from "react-hot-toast";
import { BACKEND_STATIC_URL } from "../../constants";
import { CenteredLoader } from "../Chat/Chat";

const EditServiceType = () => {
  const [name, setName] = useState();
  const [image, setImage] = useState();
  const [imageForUpdate, setImageForUpdate] = useState();
  const { id } = useParams();
  const imageRef = useRef();

  const dispatch = useDispatch();
  const {
    singleServiceType: singleService,
    message,
    errors,
    loading,
  } = useSelector((state) => state.serviceTypeReducer);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getSingleServiceType(id));
  }, [id, dispatch]);
  useEffect(() => {
    if (message) {
      toast.success(message);
      dispatch(clearServiceTypeMessages());
      navigate("/manage-services");
    }
  }, [message, dispatch]);
  // useEffect(()=>{
  //     if(!!errors){
  //         toast.error(errors)
  //         dispatch(clearServiceTypeErrors())
  //     }
  // },[errors,dispatch])
  useEffect(() => {
    setName(singleService.serviceName);
    // setImage(singleService.serviceImage)
    const img = new Image();
    if (singleService.serviceImage) {
      img.src = `${BACKEND_STATIC_URL}${singleService.serviceImage}`;
      setImage(img.src);
    }
  }, [singleService]);
  const handleUpdateServiceType = () => {
    const formData = new FormData();
    formData.append("serviceName", name);
    if (imageForUpdate) {
      formData.append("serviceImage", imageForUpdate);
    }
    dispatch(updateServiceType(formData, id));
  };
  const onImageChange = (event) => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }

    const isLess = file.size <= 5242880;
    if (!isLess) {
      toast.error("File size must be less than 5MB.");
      event.target.value = "";
      return;
    }

    setImageForUpdate(file);
    let img = file;
    setImage(URL.createObjectURL(img));
  };
  return loading ? (
    <div className="m-5">
      <CenteredLoader />
    </div>
  ) : (
    <div>
      <Container className="half">
        <Heading className="m-5 center">Edit Service Type</Heading>
        <div className="add-serviceType">
          <label htmlFor="name">Service Name</label>
          <input
            label="Enter Service Name"
            placeholder="Baloons"
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <div className="uploadimages-btn m-5">
            <div onClick={() => imageRef.current.click()}>
              <RiUpload2Fill style={{ marginRight: "1rem" }} />
              Upload Image
            </div>
          </div>
          {image && (
            <div className="create-service-images_preview m-2">
              <div className="create-service-images_preview-images">
                {[image].map((pic, ind) => {
                  return (
                    <div
                      className="create-service-images_preview-images-item"
                      key={ind}
                    >
                      <img src={image} alt="service" />
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          <div style={{ display: "none" }} className="">
            <input
              type="file"
              name="myImage"
              accept="image/*"
              ref={imageRef}
              onChange={onImageChange}
            />
          </div>
          <Button
            onClick={handleUpdateServiceType}
            className="rounded center m-2"
            disabled={!image || !name}
          >
            Update Service Type
          </Button>
        </div>
      </Container>
    </div>
  );
};

export default EditServiceType;
